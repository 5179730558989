@import url(https://fonts.googleapis.com/css?family=Roboto);
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #FBFBFD;
  color: #3E3F42;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-smoothing: antialiased;
  font-weight: 400;
  height: 100%;
  letter-spacing: -.05px;
  line-height: 22px;
  width: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  background: #F7F8FC;
}

#root {
  display: flex;
  min-width: 100%;
  min-height: 100vh;
  background: #F7F8FC;
}

#root>* {
  width: 100%;
}

.recharts-tooltip-label{
  display: none;
}

.recharts-cartesian-axis { font-family: 'Roboto', sans-serif; }
.recharts-cartesian-axis .recharts-text { font-size: 10px; }
.recharts-cartesian-axis .recharts-cartesian-axis-line { opacity: 0; }
.recharts-cartesian-axis .recharts-cartesian-axis-tick-line { opacity: 0; }
.recharts-cartesian-axis .recharts-cartesian-grid-vertical { opacity: 0; }

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
::-webkit-scrollbar-thumb {
  background: #dadada;
  border: 8px none #fff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #bbbbbb;
}
::-webkit-scrollbar-thumb:active {
  background: #bbbbbb;
}
::-webkit-scrollbar-track {
  background: #e9e9e9;
  border: 9px none #fff;
  border-radius: 10px;
  margin: 6px 0;
}
::-webkit-scrollbar-track:hover {
  background: #e9e9e9;
}
::-webkit-scrollbar-track:active {
  background: #e9e9e9;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.CalendarDay__selected {
  background: #E4E7E7;
  color: black;
  border:none;
}


